import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { FilterOption } from 'types'
import { CreatorApi, YouTubeReportRequestBody } from 'utils/creator-api'

export type CreatorsYouTubeReportFormData = {
    games: FilterOption[]
    excludedGames?: FilterOption[]
    categories?: FilterOption[]
    topics?: FilterOption[]
    countries?: FilterOption[]
    subscribersMin?: number
    subscribersMax?: number
    avgViewsMin?: number
    avgViewsMax?: number
    channelViewsMin?: number
    channelViewsMax?: number
    dateRange?: string
    dateFrom: string
    dateTo: string
    contacted?: boolean
    hasEmail?: boolean
    hasTikTok?: boolean
    keywords?: string[]
    excludedKeywords?: string[]
    tags?: FilterOption[]
}

const filterOptionsToPlain = (inputArray?: FilterOption[]) => {
    if (!inputArray || inputArray.length === 0) {
        return
    }
    // eslint-disable-next-line consistent-return
    return inputArray.map(({ value }) => value)
}

const formDataToRequestPayload = ({
    games,
    excludedGames,
    categories,
    topics,
    countries,
    hasEmail,
    hasTikTok,
    contacted,
    dateFrom,
    dateTo,
    subscribersMin,
    subscribersMax,
    avgViewsMin,
    avgViewsMax,
    channelViewsMin,
    channelViewsMax,
    keywords,
    excludedKeywords,
    tags,
}: CreatorsYouTubeReportFormData): YouTubeReportRequestBody => ({
    games: filterOptionsToPlain(games) as string[],
    excludedGames: filterOptionsToPlain(excludedGames),
    categories: filterOptionsToPlain(categories),
    topics: filterOptionsToPlain(topics),
    countries: filterOptionsToPlain(countries),
    tags: filterOptionsToPlain(tags),
    dateFrom,
    dateTo,
    hasEmail,
    hasTikTok,
    contacted,
    subscribersMin,
    subscribersMax,
    avgViewsMin,
    avgViewsMax,
    channelViewsMin,
    channelViewsMax,
    keywords,
    excludedKeywords,
})

export const useCreatorsReportYoutubeForm = (isV2: boolean) => {
    const { mutateAsync } = useMutation({
        mutationFn: async (data: CreatorsYouTubeReportFormData) => {
            const payload = formDataToRequestPayload(data)
            return CreatorApi.requestCreatorsYouTubeReport(payload, isV2)
        },
    })

    const handleSubmit = (data: CreatorsYouTubeReportFormData) =>
        mutateAsync(data)

    // Cannot use useQuery with current components
    const handleGameSearch = useCallback(async (input: string) => {
        const res = await CreatorApi.searchYouTubeGames(input)
        return res.map(
            ({ name }): FilterOption => ({ label: name, value: name })
        )
    }, [])

    // Cannot use useQuery with current components
    const handleTopicsSearch = useCallback(async (input: string) => {
        const res = await CreatorApi.searchYouTubeTopicCategories(input)
        return res.map(
            ({ name }): FilterOption => ({
                label: name.replace(/.*\//g, '').replaceAll('_', ' '),
                value: name,
            })
        )
    }, [])

    // Cannot use useQuery with current components
    const handleTagsSearch = useCallback(async (input: string) => {
        const res = await CreatorApi.searchYouTubeTags(input)
        return res.map(
            ({ name }): FilterOption => ({ label: name, value: name })
        )
    }, [])

    return {
        handleSubmit,
        handleGameSearch,
        handleTopicsSearch,
        handleTagsSearch,
    }
}
