import {
    SimpleForm,
    Create,
    FileInput,
    FileField,
    useDataProvider,
    useResourceContext,
    Toolbar,
    SaveButton,
} from 'react-admin'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { FieldValues } from 'react-hook-form'
import { resourcesIds } from '../../resources'
import { useQueryParams } from '../../hooks/useQueryParams'

const SubmitToolbar = () => {
    const dataProvider = useDataProvider()
    const resourceContext = useResourceContext()
    const { mutateAsync, isLoading } = useMutation((data: FieldValues) =>
        dataProvider.createMany(resourceContext, { data })
    )

    return (
        <Toolbar>
            <SaveButton
                onSubmit={async (data) => {
                    await mutateAsync(data)
                }}
                disabled={isLoading}
            />
        </Toolbar>
    )
}

export const PointsAllowlistCreateForm = () => {
    const dropId = useQueryParams('value')
    return (
        <Create redirect={`/${resourcesIds.POINTS_ALLOWLIST}?value=${dropId}`}>
            <SimpleForm toolbar={<SubmitToolbar />}>
                <FileInput accept="text/csv" source="pointsAllowlistCreators">
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    )
}
