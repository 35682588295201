import { useRecordContext, useSimpleFormIteratorItem } from 'react-admin'
import { RaRecord } from 'types'
import { Verification } from 'resourceDescriptions/verifications'
import { Box } from '@mui/material'
import { Chip } from '@material-ui/core'

const useThreatsField = () => {
    const { index } = useSimpleFormIteratorItem()
    const record = useRecordContext<RaRecord<Verification>>()

    const profile = record.context?.profiles?.[index]

    const filteredThreats = Object.entries(profile?.threats || {})
        .filter(([, value]) => value)
        .map(([name]) => name)

    return {
        value: filteredThreats,
    }
}

export const ThreatsField = () => {
    const { value } = useThreatsField()

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {value.map((threatName) => (
                <Chip key={threatName} label={threatName} />
            ))}
        </Box>
    )
}
