import omit from 'ramda/src/omit'
import isEmpty from 'ramda/src/isEmpty'
import equals from 'ramda/src/equals'
import { resourcesIds } from 'resources'
import {
    getListKey,
    editListItemsIdKey,
    getOneKey,
    editOneItemIdKey,
    updateKey,
    createKey,
    deleteKey,
    deleteManyKey,
} from 'keys'
import uploadFile from 'utils/uploadFile'
import { v4 as uuid } from 'uuid'

export default {
    [resourcesIds.POINTS_MARKETPLACE_RESOURCE_ID]: {
        [getListKey]: () => ({
            endpointId: 'GET_POINTS_MARKETPLACE_ITEMS',
            payload: {},
        }),
        [editListItemsIdKey]: (rawData: Record<string, any>[]) => rawData,
        [editOneItemIdKey]: (rawData: Record<string, any>[]) =>
            isEmpty(rawData) ? { id: uuid() } : rawData,
        [getOneKey]: (id: string) => ({
            endpointId: 'GET_POINTS_MARKETPLACE_ITEMS',
            payload: { id },
        }),
        [updateKey]: async (params: Record<string, any>) => {
            const { data } = params
            let imageUrl
            if (data.newImage) {
                imageUrl = await uploadFile(data.newImage.rawFile, 1440)
            }
            return {
                endpointId: 'MANAGE_MARKETPLACE_ITEM',
                payload: {
                    ...omit(['quantity', 'newImage'], data),
                    ...(imageUrl ? { image: imageUrl } : {}),
                    ...(equals('', data.quantity)
                        ? {}
                        : { quantity: data.quantity }),
                },
            }
        },
        [createKey]: async (data: Record<string, any>) => {
            let imageUrl
            if (data.newImage) {
                imageUrl = await uploadFile(data.newImage.rawFile, 1440)
            }
            return {
                endpointId: 'MANAGE_MARKETPLACE_ITEM',
                payload: {
                    ...omit(['quantity', 'newImage'], data),
                    ...(imageUrl ? { image: imageUrl } : {}),
                    id: uuid(),
                    ...(equals('', data.quantity)
                        ? {}
                        : { quantity: data.quantity }),
                },
            }
        },
        [deleteKey]: (params: Record<string, any>) => {
            const { id } = params
            return {
                endpointId: 'MANAGE_MARKETPLACE_ITEM',
                payload: { deleteItems: [id] },
            }
        },
        [deleteManyKey]: (params: Record<string, any>) => {
            const { ids } = params
            return {
                endpointId: 'MANAGE_MARKETPLACE_ITEM',
                payload: { deleteItems: ids },
            }
        },
    },
}
