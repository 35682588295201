import {
    Button,
    Datagrid,
    DateField,
    List,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    SaveButton,
    useDataProvider,
    useListContext,
    EditButton,
    EmailField,
    ArrayField,
    useRecordContext,
    FunctionField,
} from 'react-admin'
import {
    readableStates,
    Verification,
    VerificationState,
    verificationStates,
} from 'resourceDescriptions/verifications'
import { DialogContextProvider, useDialogContext } from 'contexts/dialog'
import { DialogBase } from 'components/DialogBase'
import { resourcesIds } from 'resources'
import { RaRecord } from 'types'
import { SelectStateInput } from './components/SelectStateInput'

const verificationListFilters = [
    <TextInput
        alwaysOn
        label="User Id"
        multiline
        key="userId"
        source="userId"
    />,
    <SelectInput
        alwaysOn
        key="state"
        source="state"
        emptyValue={verificationStates[0]}
        choices={verificationStates.map((verificationState) => ({
            id: verificationState,
            name: readableStates[verificationState],
        }))}
    />,
]

const ManageVerificationStateToolbar = () => {
    const dialog = useDialogContext()
    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'space-around',
            }}
        >
            <SaveButton />
            <Button label="Cancel" onClick={() => dialog.setIsOpened(false)} />
        </div>
    )
}

const ManageVerificationStateButton = () => {
    const dialog = useDialogContext()
    const list = useListContext()
    const verification = useRecordContext<RaRecord<Verification>>()

    const dataProvider = useDataProvider()

    return (
        <>
            <DialogBase>
                <SimpleForm
                    toolbar={<ManageVerificationStateToolbar />}
                    onSubmit={async (item) => {
                        await dataProvider.update(resourcesIds.VERIFICATIONS, {
                            id: verification.id,
                            data: item,
                            previousData: verification,
                        })
                        await list.refetch()
                        dialog.setIsOpened(false)
                    }}
                >
                    <TextInput source="pk" label="User Id" disabled />
                    <SelectStateInput source="state" />
                </SimpleForm>
            </DialogBase>
            <Button onClick={() => dialog.setIsOpened(true)} label="Manage" />
        </>
    )
}

export const VerificationsList = () => {
    return (
        <List empty={false} filters={verificationListFilters}>
            <Datagrid>
                <TextField label="User Id" source="pk" />
                <FunctionField
                    label="State"
                    render={(field: { state: VerificationState }) =>
                        readableStates[field.state]
                    }
                />
                <DateField showTime source="created" />
                <DateField showTime source="modified" />
                <EmailField label="Email" source="context.email" />
                <DateField
                    showTime
                    label="Account Created"
                    source="context.accountCreatedAt"
                />
                <ArrayField label="Profiles" source="context.profiles">
                    <Datagrid
                        bulkActionButtons={false}
                        unselectable="on"
                        isRowExpandable={() => false}
                        isRowSelectable={() => false}
                    >
                        <TextField label="Username" source="mnemonicId" />
                        <TextField source="displayName" />
                        <TextField source="platform" />
                        <TextField label="Platform Id" source="platformId" />
                    </Datagrid>
                </ArrayField>
                <DialogContextProvider>
                    <ManageVerificationStateButton />
                </DialogContextProvider>
                <EditButton />
            </Datagrid>
        </List>
    )
}
