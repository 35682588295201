import { resourcesIds } from 'resources'
import {
    getListKey,
    editListItemsIdKey,
    getOneKey,
    editOneItemIdKey,
    updateKey,
    updateManyKey,
    filterListKey,
    deleteKey,
} from 'keys'
import pick from 'ramda/src/pick'
import filter from 'ramda/src/filter'
import toPairs from 'ramda/src/toPairs'
import reduce from 'ramda/src/reduce'
import isEmpty from 'ramda/src/isEmpty'
import equals from 'ramda/src/equals'

export default {
    [resourcesIds.CAMPAIGNS_RESOURCE_ID]: {
        [getListKey]: () => ({
            endpointId: 'GET_DROP_EVENTS',
            payload: {
                section: 'admin',
                all: true,
            },
        }),
        [editListItemsIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => rawData,
        [editOneItemIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => rawData,
        [getOneKey]: (id: string): Record<string, any> => ({
            endpointId: 'DROP_GET_EVENT',
            payload: {
                id,
            },
        }),
        [updateKey]: (params: Record<string, any>): Record<string, any> => {
            const { data } = params
            return {
                endpointId: 'DROP_EDIT_EVENT',
                payload: {
                    dropEventPayload: {
                        ...pick(['id', 'daresCountResetDate'], data),
                    },
                },
            }
        },
        [updateManyKey]: (ids: string[]): Record<string, any> => ({
            endpointId: 'UPDATE_CAMPAIGN_DEAL_ID',
            payload: {
                ids,
            },
        }),
        [filterListKey]: (
            filterData: Record<string, any>
        ): Record<string, any> => {
            const { filterParams, data: products } = filterData
            const filtersMap = {
                isLive: (
                    value: boolean,
                    data: Record<string, any>[]
                ): Record<string, any>[] => {
                    if (equals(value, false)) return data
                    return filter((campaign) => {
                        const { isLive, subscriptionStatus, isUserActivated } =
                            campaign
                        return (
                            equals(isLive, true) &&
                            equals(subscriptionStatus, 'ACTIVE') &&
                            equals(isUserActivated, true)
                        )
                    }, data)
                },
            } as {
                [key: string]: (
                    value: boolean,
                    data: Record<string, any>[]
                ) => Record<string, any>[]
            }

            const dataFiltered = reduce(
                (
                    acc: Record<string, any>[],
                    filterProp: any[]
                ): Record<string, any>[] => {
                    const [prop, value] = filterProp

                    return filtersMap[prop](
                        value,
                        isEmpty(acc) ? products : acc
                    )
                },
                [],
                toPairs(filterParams)
            )
            return { dataFiltered }
        },
        [deleteKey]: (params: Record<string, any>) => {
            const { id: campaignId } = params
            return {
                endpointId: 'DELETE_DROP_EVENT',
                payload: { campaignId },
            }
        },
    },
}
