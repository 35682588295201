import {
    Edit,
    SimpleForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    DateTimeInput,
} from 'react-admin'
import { Box } from '@mui/material'
import { SelectStateInput } from './components/SelectStateInput'
import styles from './verifications.module.scss'
import { ThreatsField } from './components/ThreatsField'

export const EditVerification = () => {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <TextInput source="pk" label="User Id" disabled />
                    <SelectStateInput source="state" />
                    <DateTimeInput source="created" disabled />
                    <DateTimeInput source="modified" disabled />
                    <TextInput label="Email" source="context.email" disabled />
                    <DateTimeInput
                        label="Account Created"
                        source="context.accountCreatedAt"
                        disabled
                    />
                    <ArrayInput
                        label="Profiles"
                        source="context.profiles"
                        disabled
                    >
                        <SimpleFormIterator
                            // remove index
                            getItemLabel={() => ''}
                            disabled
                            className={styles.simpleFormIterator}
                        >
                            <TextInput
                                label="Username"
                                source="mnemonicId"
                                disabled
                            />
                            <TextInput source="displayName" disabled />
                            <TextInput source="platform" disabled />
                            <TextInput
                                label="Platform Id"
                                source="platformId"
                                disabled
                            />
                            <ThreatsField />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Box>
            </SimpleForm>
        </Edit>
    )
}
