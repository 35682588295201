import { z } from 'zod'
import { getCookie } from 'cookies'
import { HTTP_STATUS_CODES, wait } from 'utils/ajax'
import requestHandler from 'utils/requestHandler'
import { daredropApiUrl } from 'constants/app'
import { authNames } from 'constants/cookies'

export type DareDropApiRequestBaseConfig = {
    payload: unknown
    endpointId: string
}

type DareDropApiRequestConfig<TSchema> = DareDropApiRequestBaseConfig & {
    schema: z.ZodType<TSchema>
}

type DareDropApiError = { message: string }

type DareDropApiSuccessResponse<T> = { statusCode: 200; body: T }

type DareDropApiFailResponse = { statusCode: number; error: DareDropApiError }

type DareDropApiResponse<T> =
    | DareDropApiSuccessResponse<T>
    | DareDropApiFailResponse

const isSuccessResponse = <T>(
    response: unknown
): response is DareDropApiSuccessResponse<T> =>
    typeof response === 'object' &&
    response !== null &&
    'statusCode' in response &&
    response.statusCode === HTTP_STATUS_CODES.OK

const daredropApiRequest = async <TSchema>(
    config: DareDropApiRequestConfig<TSchema>,
    retries = 1
): Promise<TSchema> => {
    const { schema, payload, endpointId } = config

    const response = await requestHandler<DareDropApiResponse<unknown>>({
        url: daredropApiUrl,
        body: {
            endpointId,
            payload,
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })

    if (!isSuccessResponse(response)) {
        if (response.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED) {
            await wait(2500)
            return daredropApiRequest(config, retries - 1)
        }
        throw new Error(response.error.message)
    }

    return schema.parse(response.body)
}

export default daredropApiRequest
