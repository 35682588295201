export const setCookie = (name: string, value: string, exp: number): void => {
    document.cookie = `${name}=${value}${
        exp ? `; expires=${new Date(exp * 1000).toUTCString()}` : ''
    } ;path=/`
}

export const getCookies = (): Record<string, any> | undefined => {
    const { cookie } = document
    if (cookie === '') return {}
    if (!cookie) return undefined
    return cookie.split('; ').reduce((acc, item) => {
        const [name, value] = item.split('=')
        return { ...acc, [name]: value }
    }, {})
}

export const getCookie = (name: string): string | undefined => {
    const cookies = getCookies()
    if (cookies) return cookies[name]
    return undefined
}
