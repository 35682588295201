import React, { ComponentType } from 'react'
import { List, Datagrid, TextField, TopToolbar, Button } from 'react-admin'
import { Add } from '@material-ui/icons'
import { useQueryParams } from 'hooks/useQueryParams'
import browserHistory from 'browserHistory'
import { resourcesIds } from 'resources'

const PointsAllowlistActions = () => {
    const dropId = useQueryParams('value')

    return (
        <TopToolbar>
            <Button
                onClick={() => {
                    browserHistory.push({
                        pathname: `${resourcesIds.POINTS_ALLOWLIST}/create`,
                        search: `?value=${dropId}`,
                    })
                }}
                startIcon={<Add />}
                label="Create"
            />
        </TopToolbar>
    )
}
export const PointsAllowlistList: ComponentType = () => (
    <List
        disableSyncWithLocation
        empty={false}
        title="Points Allowlist Creators"
        actions={<PointsAllowlistActions />}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label="Display Name" source="displayName" />
            <TextField label="Platform" source="platform" />
            <TextField label="Platform Id" source="platformId" />
        </Datagrid>
    </List>
)
