import { parse } from 'csv-parse/browser/esm/sync'

export const parseCsv = (
    data: Buffer | string
): Record<string, string | number>[] => {
    const csvString = typeof data === 'string' ? data : data.toString('utf8')
    return parse(csvString, {
        columns: true,
        trim: true,
        rtrim: true,
        cast: true,
        skip_empty_lines: true,
    })
}
