import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import {
    Edit,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRecordContext,
    useRedirect,
} from 'react-admin'
import { idSerializer, Reward } from 'resourceDescriptions/rewards'
import { resourcesIds } from 'resources'
import { Button } from '@material-ui/core'
import { Box } from '@mui/material'
import { DialogBase } from '../../components/DialogBase'
import { DialogContextProvider, useDialogContext } from '../../contexts/dialog'
import { DaredropApi } from '../../utils/daredrop-api'

export const RewardEdit = () => {
    const notify = useNotify()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify(`Decrypting rewards`, { type: 'info', undoable: true })
        redirect(`/${resourcesIds.REWARDS_RESOURCE_ID}`)
    }

    const transformData = (data: Record<string, any>) => {
        const { available, decryptAmount } = data
        return {
            ...data,
            available: available - decryptAmount,
        }
    }

    return (
        <Edit mutationOptions={{ onSuccess }} transform={transformData}>
            <DialogContextProvider>
                <RewardTransferForm />
            </DialogContextProvider>
            <RewardDecryptForm />
        </Edit>
    )
}

const RewardDecryptFormToolbar = () => {
    const { type } = useRecordContext<Reward>()
    return (
        <Toolbar>
            <SaveButton
                alwaysEnable
                icon={<ArrowDownwardIcon />}
                label={`Download and use ${
                    type === 'dropCode' ? 'codes' : 'keys'
                }`}
            />
        </Toolbar>
    )
}

const RewardDecryptForm = () => {
    const { available, type } = useRecordContext<Reward>()
    const helperText = `Available ${
        type === 'dropCode' ? 'codes' : 'keys'
    }: ${available}`
    const label = `${type === 'dropCode' ? 'Codes' : 'Keys'} amount`

    return (
        <SimpleForm toolbar={<RewardDecryptFormToolbar />}>
            <NumberInput
                defaultValue={1}
                min={1}
                max={available}
                label={label}
                helperText={helperText}
                source="decryptAmount"
            />
        </SimpleForm>
    )
}

const RewardTransferFormToolbar = () => {
    const dialog = useDialogContext()
    return (
        <Toolbar
            sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
        >
            <SaveButton
                alwaysEnable
                icon={<ArrowDownwardIcon />}
                label="Transfer"
            />
            <Button color="secondary" onClick={() => dialog.setIsOpened(false)}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const RewardTransferForm = () => {
    const reward = useRecordContext<Reward>()
    const dialog = useDialogContext()
    const redirect = useRedirect()
    return (
        <>
            <DialogBase>
                <SimpleForm
                    toolbar={<RewardTransferFormToolbar />}
                    /* @ts-ignore */
                    onSubmit={async (data: {
                        transferTo: string
                        brandId: string
                        amount: number
                        type: string
                        id: string
                    }) => {
                        await DaredropApi.transferRewards({
                            transferTo: data.transferTo.trim(),
                            transferFrom: data.brandId,
                            reward: {
                                id: idSerializer.deserialize(data.id).rewardId,
                                type: data.type,
                            },
                            amount: data.amount,
                        })
                        dialog.setIsOpened(false)
                        redirect(`/${resourcesIds.REWARDS_RESOURCE_ID}`)
                    }}
                >
                    <TextInput
                        fullWidth
                        helperText="Current owner"
                        label="Owner ID"
                        source="brandId"
                        disabled
                    />
                    <TextInput
                        fullWidth
                        helperText="User of whom the reward will be transferred"
                        label="User ID"
                        source="transferTo"
                    />
                    <NumberInput
                        fullWidth
                        label="Amount to transfer"
                        helperText={`Maximum is the available amount (${reward.available})`}
                        source="amount"
                        defaultValue={1}
                        min={1}
                        max={reward.available}
                    />
                </SimpleForm>
            </DialogBase>
            <Box sx={{ padding: '1rem' }}>
                <Button
                    variant="contained"
                    onClick={() => dialog.setIsOpened(true)}
                >
                    Transfer
                </Button>
            </Box>
        </>
    )
}
