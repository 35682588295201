import { resourcesIds } from 'resources'
import {
    getListKey,
    editListItemsIdKey,
    getOneKey,
    updateKey,
    editOneItemIdKey,
    createKey,
} from 'keys'
import { getCurrentQueryValue } from 'utils/common'
import { transactionTypesMap } from './points-transactions'

type CreateData = {
    userId: string
    modificationValue: number
    transactionType: keyof typeof transactionTypesMap
}

export default {
    [resourcesIds.CAMPAIGN_POINTS_DETAILS]: {
        [getListKey]: () => {
            const queryValue = getCurrentQueryValue()

            if (!queryValue) {
                return undefined
            }

            return {
                endpointId: 'ADMIN_CAMPAIGN_CREATORS_REPORT',
                payload: {
                    dropEventId: queryValue,
                },
            }
        },
        [editListItemsIdKey]: (rawData: any[]) =>
            rawData.map((creator) => {
                const { userId, dropEventId } = creator
                return {
                    ...creator,
                    id: `${userId}|${dropEventId}`,
                }
            }),
        [getOneKey]: (id: string): Record<string, any> => {
            const [userId, campaignId] = id.split('|')
            return {
                endpointId: 'GET_CREATOR_CAMPAIGN_POINTS',
                payload: { userId, campaignId },
            }
        },
        [editOneItemIdKey]: (rawData: Record<string, any>) => {
            const { userId, campaignId } = rawData
            return { ...rawData, id: `${userId}|${campaignId}` }
        },
        [updateKey]: (params: any) => {
            const { data, previousData } = params
            const { campaignId, pointsAwarded: newAmount, userId } = data
            const { pointsAwarded: oldAmount } = previousData
            const modificationValue = newAmount - oldAmount
            return {
                endpointId: 'UPDATE_CREATOR_CAMPAIGN_POINTS',
                payload: { campaignId, modificationValue, userId },
            }
        },
        [createKey]: (data: CreateData) => {
            const { userId, modificationValue, transactionType } = data
            const campaignId = getCurrentQueryValue()
            const payload = { userId, campaignId, modificationValue }
            return {
                endpointId:
                    transactionType ===
                    transactionTypesMap.adminPointsModification
                        ? 'UPDATE_CREATOR_CAMPAIGN_POINTS'
                        : 'ADD_PAID_OFF_PLATFORM_POINTS',
                payload,
            }
        },
    },
}
