import { ComponentType, useState } from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    ImageInput,
    required,
    useRecordContext,
} from 'react-admin'
import { Grid } from '@material-ui/core'

const EditMarketplaceItem: ComponentType = () => {
    const [newImage, setNewImage] = useState(false)

    const ImageDisplay = (props: any) => {
        const record = useRecordContext(props)
        return (
            <img
                alt={record.itemName}
                style={{ maxHeight: 400 }}
                src={record.undefined || record.image}
            />
        )
    }

    return (
        <Edit redirect="list">
            <SimpleForm>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid container>
                        <Grid item xs={3}>
                            <TextInput
                                source="itemName"
                                validate={required()}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput
                                source="category"
                                validate={required()}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput
                                source="cost"
                                label="Cost (points)"
                                validate={required()}
                                helperText="1,000 points = $1"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source="quantity" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                source="itemDescription"
                                fullWidth
                                multiline
                                validate={required()}
                            />
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            marginLeft: 20,
                        }}
                    >
                        <ImageInput
                            accept="image/*"
                            onChange={(e) => {
                                setNewImage(!!e)
                            }}
                            source="newImage"
                            labelSingle="Drop a picture to upload, or click to select it. Best displayed in 280x160 ratio."
                        >
                            <ImageDisplay />
                        </ImageInput>
                        {!newImage && <ImageDisplay />}
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}

export default EditMarketplaceItem
