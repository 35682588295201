import { ComponentType, useState } from 'react'
import Form from 'components/Form'
import FilterAutocomplete from 'components/FilterAutocomplete'
import languageOptions from 'utils/languageOptions'
import FilterTwitchGamesAutocomplete from 'components/FilterTwitchGamesAutocomplete'
import { RadioField } from 'components/RadioField'
import { getGameStats } from 'utils/creatorApi'
import { YesNoFilter } from 'components/YesNoFilter'
import { InputOption } from 'types/forms'
import { CcvField } from 'components/CcvField'
import { PercentageField } from 'components/PercentageField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { onCreatorsReportSubmit } from '../creators-report-alt/RequestCreatorsReportFormAlt'
import FieldNumeric from '../../components/FieldNumeric'

const DatePicker = () => (
    <RadioField
        source="timeRange"
        label="Date range"
        options={[
            {
                value: 7,
                label: '7 days',
            },
            {
                value: 14,
                label: '14 days',
            },
            {
                value: 30,
                label: '30 days',
            },
            {
                value: 60,
                label: '60 days',
            },
            {
                value: 90,
                label: '90 days',
            },
            {
                value: 180,
                label: '180 days',
            },
            {
                value: 365,
                label: '365 days',
            },
        ]}
    />
)

type RequestCreatorsReportAltFormData = {
    timeRange: string
    languages: InputOption<string>[]
    gameIds: InputOption<string>[]
    excludedGameIds: InputOption<string>[]
    minCcv?: number
    maxCcv?: number
    hasEmail?: boolean
    contacted?: boolean
    minStreamTimePercentage?: number
}

const style = {
    width: 560, // to fit 1B hours
    margin: '15px auto',
}
const formContainerStyle = {
    display: 'flex',
    gap: 20,
}

const leftColumnStyle = {
    margin: 10,
    gap: 10,
    display: 'flex',
    flexDirection: 'column' as const,
}

const rightColumnStyle = {
    display: 'flex',
    flexShrink: 0,
    width: 220,
    justifyContent: 'space-between',
}

const statsStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
}

const statItemStyle = {
    fontSize: 30,
}

const statItemValueStyle = {
    fontWeight: 600,
}

type HistoryItem = {
    games?: string[]
    excludedGames?: string[]
    languages?: string[]
    minCcv?: number
    maxCcv?: number
    minStreamTimePercentage?: number
    timeRange: string
    hasTiktok?: boolean
    count: number
}

const SearchHistory = ({ items }: { items: HistoryItem[] }) => {
    return (
        <TableContainer sx={{ marginTop: 2 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Games</TableCell>
                        <TableCell align="right">Excluded Games</TableCell>
                        <TableCell align="right">Languages</TableCell>
                        <TableCell align="right">Min CCV</TableCell>
                        <TableCell align="right">Max CCV</TableCell>
                        <TableCell align="right">Stream Time %</TableCell>
                        <TableCell align="right">Date range</TableCell>
                        <TableCell align="right">Has TikTok</TableCell>
                        <TableCell align="right">Creators №</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row, i) => (
                        <TableRow
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={i}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.games?.join(', ')}
                            </TableCell>
                            <TableCell align="right">
                                {row.excludedGames?.join(', ')}
                            </TableCell>
                            <TableCell align="right">
                                {row.languages?.join(', ')}
                            </TableCell>
                            <TableCell align="right">
                                {row.minCcv || 0}
                            </TableCell>
                            <TableCell align="right">
                                {row.maxCcv === 0 ? '' : row.maxCcv}
                            </TableCell>
                            <TableCell align="right">
                                {row.minStreamTimePercentage
                                    ? `${row.minStreamTimePercentage} %`
                                    : ''}
                            </TableCell>
                            <TableCell align="right">
                                {row.timeRange} days
                            </TableCell>
                            <TableCell align="right">{row.hasTiktok}</TableCell>
                            <TableCell align="right">
                                {row.count.toLocaleString()}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const GameStatsReportForm: ComponentType = () => {
    const [data, setData] = useState({
        count: 0,
        followers: 0,
        averageViewers: 0,
        averageViewersSelected: 0,
        durationMinutes: 0,
        durationMinutesSelected: 0,
    })
    const [history, setHistory] = useState([] as HistoryItem[])
    const [error, setError] = useState('')
    const onSubmit = async (formData: RequestCreatorsReportAltFormData) => {
        const res = await getGameStats({
            ...formData,
            excludedGameIds: formData.excludedGameIds?.map(
                (game) => game.value
            ),
            gameIds: formData.gameIds?.map((game) => game.value),
            languages: formData.languages?.map((language) => language.value),
        })
        if (res.body) {
            setHistory([
                ...history,
                {
                    games: formData.gameIds.map((x) => x.label),
                    excludedGames: formData.excludedGameIds.map((x) => x.label),
                    timeRange: formData.timeRange,
                    languages: formData.languages.map((x) => x.label),
                    // hasTiktok: formData.
                    minCcv: formData.minCcv,
                    maxCcv: formData.maxCcv,
                    minStreamTimePercentage: formData.minStreamTimePercentage,
                    count: res.body.count,
                },
            ])
            setData(res.body)
            setError('')
        } else {
            setError('Error')
        }
        return res
    }

    return (
        <div>
            <Form
                clear={false}
                style={style}
                submits={[
                    { onSubmit, text: 'Get Stats' },
                    { onSubmit: onCreatorsReportSubmit, text: 'Get Report' },
                ]}
                defaults={{ gameIds: [], languages: [], excludedGameIds: [] }}
            >
                <div style={formContainerStyle}>
                    <div style={leftColumnStyle}>
                        <FilterTwitchGamesAutocomplete
                            label="Games"
                            source="gameIds"
                            multiple
                        />
                        <FilterTwitchGamesAutocomplete
                            label="Excluded games"
                            source="excludedGameIds"
                            multiple
                        />
                        <FilterAutocomplete
                            label="Languages"
                            source="languages"
                            options={languageOptions}
                        />
                        <FieldNumeric
                            source="maxCcv"
                            label="Budget ($)"
                            modifier={(maxCcv) => maxCcv / 0.07}
                            reverseModifier={(budget) => budget * 0.07}
                        />
                        <CcvField />
                        <PercentageField
                            source="minStreamTimePercentage"
                            label=">= % of Stream Time"
                        />
                    </div>
                    <div style={rightColumnStyle}>
                        <DatePicker />
                        <div>
                            <YesNoFilter
                                label="Has TikTok"
                                source="hasTiktok"
                            />
                        </div>
                    </div>
                </div>
            </Form>
            <div style={{ ...style, ...statsStyle }}>
                <div>
                    <div style={statItemStyle}>
                        Creators №:{' '}
                        <span style={statItemValueStyle}>
                            {data.count.toLocaleString()}
                        </span>
                    </div>
                    <div style={statItemStyle}>
                        Selected CCV Total:{' '}
                        <span style={statItemValueStyle}>
                            {Math.round(
                                data.averageViewersSelected * data.count
                            ).toLocaleString()}
                        </span>
                    </div>
                    <div style={statItemStyle}>
                        Total Watch Time:{' '}
                        <span style={statItemValueStyle}>
                            {Math.round(
                                (data.durationMinutesSelected / 60) *
                                    data.averageViewersSelected
                            ).toLocaleString()}{' '}
                            hours
                        </span>
                    </div>
                    <div style={statItemStyle}>
                        Total Media Value:{' '}
                        <span style={statItemValueStyle}>
                            $
                            {Math.round(
                                (data.durationMinutesSelected / 60) *
                                    data.averageViewersSelected *
                                    1.5
                            ).toLocaleString()}
                        </span>
                    </div>
                    <div style={statItemStyle}>
                        Total followers:
                        <span style={statItemValueStyle}>
                            {' '}
                            {data.followers.toLocaleString()}
                        </span>
                    </div>
                    {error}
                </div>
            </div>
            <SearchHistory items={history} />
        </div>
    )
}

export default GameStatsReportForm
