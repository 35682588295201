import { resourcesIds } from 'resources'
import { getOneKey, editOneItemIdKey, updateKey } from 'keys'
import { omit } from 'ramda'
import requestHandler from 'utils/requestHandler'
import { getCookie } from 'cookies'
import { authNames } from 'constants/cookies'
import { daredropApiUrl } from 'constants/app'
import { DropEvent, ProductType } from 'types'

export function hasValidDares(dropEvent: DropEvent) {
    if (!dropEvent.dares.length) {
        return false
    }
    return dropEvent.dares.some(
        (dare) => dare.product.type !== ProductType.Placeholder
    )
}

export default {
    [resourcesIds.MANAGE_CAMPAIGN]: {
        [getOneKey]: (id: string): Record<string, any> => ({
            endpointId: 'DROP_GET_EVENT',
            payload: {
                id,
            },
        }),
        [editOneItemIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => rawData,
        [updateKey]: async (params: any): Promise<Record<string, any>> => {
            const { data, previousData } = params as {
                data: DropEvent & {
                    activateInvoice: boolean
                    campaignHasNoValidDares: boolean
                    campaignHasNoInitialProduct: boolean
                }
                previousData: DropEvent
            }
            const {
                approved,
                activateInvoice,
                id,
                initialProduct,
                campaignHasNoValidDares,
                campaignHasNoInitialProduct,
            } = data

            const { approved: prevApproved } = previousData

            const isApprove = prevApproved === false && approved
            const isInvoiceApprove = prevApproved === false && approved

            if (isApprove || isInvoiceApprove) {
                if (!hasValidDares(data) && !campaignHasNoValidDares) {
                    throw new Error('Campaign has no valid dares')
                }
                if (!campaignHasNoInitialProduct && !initialProduct?.length) {
                    throw new Error('Campaign has no initial product')
                }
            }

            if (isInvoiceApprove) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_EDIT_EVENT',
                        payload: {
                            dropEventPayload: {
                                ...omit(['status', 'approved'], data),
                            },
                        },
                        authentication: getCookie(authNames.ADMIN_APP_TOKEN),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            if (activateInvoice) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_ACTIVATE_EVENT',
                        payload: {
                            id,
                            method: 'INVOICE',
                        },
                        authentication: getCookie(authNames.ADMIN_APP_TOKEN),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            if (isApprove) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_ADMIN_AUDIT',
                        payload: {
                            audit: 'ACTIVATE',
                            id,
                        },
                        authentication: getCookie(authNames.ADMIN_APP_TOKEN),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            return {
                endpointId: 'DROP_EDIT_EVENT',
                payload: {
                    dropEventPayload: {
                        ...omit(['status', 'approved'], data),
                    },
                },
            }
        },
    },
}
