import {
    SimpleForm,
    TextInput,
    Edit,
    BooleanInput,
    SelectArrayInput,
    DateTimeInput,
    TopToolbar,
    ListButton,
    useRecordContext,
} from 'react-admin'
import { Button } from '@mui/material'
import LoginIcon from '@material-ui/icons/Input'
import { signInAs } from '../../utils/authApi'

const PostEditActions = () => {
    const { id } = useRecordContext() || {}
    return (
        <TopToolbar>
            <ListButton />
            <Button
                size="small"
                startIcon={<LoginIcon />}
                color="primary"
                onClick={async () => {
                    await signInAs(id as string)
                }}
            >
                Sign in as
            </Button>
        </TopToolbar>
    )
}

const User = () => (
    <Edit actions={<PostEditActions />}>
        <SimpleForm>
            <TextInput fullWidth label="User id" source="pk" disabled />
            <TextInput fullWidth label="Email" source="email" />
            <BooleanInput label="Confirmed" source="isConfirmed" />
            <DateTimeInput source="confirmedAt" disabled />
            <BooleanInput label="Banned" source="isBanned" />
            <SelectArrayInput
                source="groups"
                choices={[
                    { id: 'betaTester', name: 'Beta tester' },
                    { id: 'viewer', name: 'Viewer' },
                    { id: 'creator', name: 'Creator' },
                    { id: 'brand', name: 'Brand' },
                    { id: 'agency', name: 'Agency' },
                    {
                        id: 'agencyAccountManager',
                        name: 'Agency account manager',
                    },
                    { id: 'admin', name: 'Admin' },
                ]}
            />
        </SimpleForm>
    </Edit>
)

export default User
