import { getCookie, setCookie } from 'cookies'
import { AuthProvider } from 'react-admin'
import browserHistory from 'browserHistory'
import { AuthApiTokens } from 'types'
import { refreshAuthToken, signIn, validateAndParseToken } from 'utils/authApi'
import { getErrorMessage } from 'utils/common'
import { HTTP_STATUS_CODES } from 'utils/ajax'
import { authNames } from 'constants/cookies'

const clearAuth = () => {
    setCookie(authNames.ADMIN_APP_TOKEN, '', -1)
    setCookie(authNames.ADMIN_APP_REFRESH_TOKEN, '', -1)
}

const setCookieAuth = ({ accessToken, refreshToken }: AuthApiTokens) => {
    const [{ exp: accessTokenExp }, { exp: refreshTokenExp }] = [
        validateAndParseToken(accessToken),
        validateAndParseToken(refreshToken),
    ]
    setCookie(authNames.ADMIN_APP_TOKEN, accessToken, accessTokenExp)
    setCookie(authNames.ADMIN_APP_REFRESH_TOKEN, refreshToken, refreshTokenExp)
}

export const isStatusIncluded = (err: unknown): err is { statusCode: number } =>
    typeof err === 'object' && err !== null && 'statusCode' in err

export const isUnauthorizedError = (err: unknown) => {
    if (isStatusIncluded(err)) {
        return err.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED
    }
    return false
}

const validateOrRefreshToken = async (
    accessToken: string | undefined,
    refreshToken: string | undefined
) => {
    if (accessToken) {
        try {
            return validateAndParseToken(accessToken)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err)
        }
    }

    if (refreshToken) {
        try {
            const { accessToken: newAccessToken } = await refreshAuthToken(
                refreshToken
            )
            const parsedToken = validateAndParseToken(newAccessToken)
            setCookieAuth({ accessToken: newAccessToken, refreshToken })
            return parsedToken
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    }

    throw new Error('Refresh token is required')
}

const logout = async () => {
    clearAuth()
    browserHistory.replace('/login')
}

export default {
    login: async ({
        username,
        password,
    }: {
        username: string
        password: string
    }) => {
        clearAuth()
        try {
            const { accessToken, refreshToken } = await signIn({
                email: username,
                password,
            })
            setCookieAuth({ accessToken, refreshToken })
        } catch (err) {
            throw new Error(getErrorMessage(err))
        }
    },
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: async (err: unknown) => {
        if (isUnauthorizedError(err)) {
            await logout()
            // eslint-disable-next-line no-console
            console.error(err)
            throw new Error('Unauthorized. Please login again')
        }
    },
    // chen the user navigates, make sure that their credentials are still valid
    checkAuth: async () => {
        const [accessToken, refreshToken] = [
            getCookie(authNames.ADMIN_APP_TOKEN),
            getCookie(authNames.ADMIN_APP_REFRESH_TOKEN),
        ]
        try {
            await validateOrRefreshToken(accessToken, refreshToken)
        } catch (err) {
            await logout()
            throw new Error(getErrorMessage(err))
        }
    },
    logout,
    getPermissions: async () => {
        const accessToken = getCookie(authNames.ADMIN_APP_TOKEN)
        const refreshToken = getCookie(authNames.ADMIN_APP_REFRESH_TOKEN)
        const { groups } = await validateOrRefreshToken(
            accessToken,
            refreshToken
        )
        return groups
    },
} satisfies AuthProvider
