import { resourcesIds } from 'resources'
import {
    createKey,
    editListItemsIdKey,
    editOneItemIdKey,
    getListKey,
} from 'keys'
import { parseCsv } from '../utils/parseCsv'
import { getCurrentQueryValue } from '../utils/common'
import { PointsAllowlistCreator } from '../types'

export const pointsAllowlist = {
    [resourcesIds.POINTS_ALLOWLIST]: {
        [createKey]: async (data: {
            pointsAllowlistCreators: {
                rawFile: File
                src: string
                title: string
            }
        }) => {
            const dataParsed = parseCsv(
                await data.pointsAllowlistCreators.rawFile.text()
            )

            const dropId = getCurrentQueryValue()

            if (!dropId) {
                throw new Error('No drop id.')
            }

            const creators = dataParsed
                .map((item, idx) => {
                    if (
                        'username' in item &&
                        typeof item.username === 'string'
                    ) {
                        return {
                            platform: 'twitch',
                            mnemonicId: item.username,
                        }
                    }
                    if ('userId' in item && typeof item.userId === 'string') {
                        return {
                            platform: 'youtube',
                            mnemonicId: item.userId,
                        }
                    }
                    if (
                        'handleName' in item &&
                        typeof item.handleName === 'string'
                    ) {
                        return {
                            platform: 'tiktok',
                            mnemonicId: item.handleName,
                        }
                    }

                    throw new Error(
                        `Item ${
                            idx + 1
                        } incorrect. Doesn't includes any of username, userId, handleName`
                    )
                })
                .filter((creator) => creator.mnemonicId.length)

            const payload = {
                creators,
                dropId,
            }

            return {
                endpointId: 'ADD_POINTS_ALLOWLIST_CREATORS',
                payload,
            }
        },
        [getListKey]: () => {
            const dropId = getCurrentQueryValue()

            if (!dropId) {
                throw new Error('No drop id.')
            }

            return {
                endpointId: 'GET_POINTS_ALLOWLIST_CREATORS',
                payload: {
                    dropId,
                },
            }
        },
        [editListItemsIdKey]: (
            rawData: PointsAllowlistCreator[]
        ): (PointsAllowlistCreator & { id: string })[] =>
            rawData.map((item) => ({
                ...item,
                id: `${item.platform}|${item.platformId}`,
            })),
        [editOneItemIdKey]: (
            rawData: PointsAllowlistCreator
        ): PointsAllowlistCreator & { id: string } => ({
            ...rawData,
            id: `${rawData.platform}|${rawData.platformId}`,
        }),
    },
}
